<template>
  <div class="md:hidden">
    <div v-if="isOpen" class="fixed inset-0 flex z-40">

      <div class="fixed inset-0" :class="{
            'transition-opacity ease-linear duration-300': true,
            'opacity-0': !isOpen,
            'opacity-100': isOpen,
          }">
        <div class="absolute inset-0 bg-oakwood-gray-600 opacity-75"></div>
            
      </div>

      <div class="relative flex-1 flex flex-col max-w-xs w-full bg-oakwood-blue-800" :class="{
          'transition ease-in-out duration-300 transform': true,
          '-translate-x-full': !isOpen,
          'translate-x-0': isOpen,
        }">
        <div class="absolute top-0 right-0 -mr-14 p-1">
          <button class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-oakwood-gray-600" aria-label="Close sidebar" @click="toggleSidebar">
            <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
          <div class="flex-shrink-0 flex items-center px-4">
            <img class="w-full" src="@/assets/images/oakwood-homes-logo.svg" alt="Oakwood Homes logo">
          </div>
          <nav class="mt-5 px-2 space-y-1">
            <router-link v-for="link,i in routes" :to="{name: link.name}" :key="`m-m-${i}`" class="group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md focus:outline-none focus:bg-oakwood-blue-700 transition ease-in-out duration-150" :class="link.name === this.$route.name || link.name === this.$route?.meta?.parent ? 'bg-oakwood-blue-900 text-white' : 'text-oakwood-blue-400'">
              <span class="mr-4 text-xl text-oakwood-blue-400 group-hover:text-oakwood-blue-300 group-focus:text-oakwood-blue-300 transition ease-in-out duration-150 far" :class="{[link.meta.icon] : true}"></span>
              {{ link.meta.title }}
            </router-link>
            <a @click.prevent="this.logout()" class="group flex items-center p-3 text-sm leading-5 font-medium rounded-md hover:text-white focus:outline-none transition ease-in-out duration-150 text-oakwood-blue-400 hover:bg-oakwood-blue-700 focus:bg-oakwood-blue-700 cursor-pointer">
              <span class="mr-3 text-xl text-oakwood-blue-400  group-focus:text-oakwood-blue-300 transition ease-in-out duration-150 far fa-right-from-bracket group-hover:text-oakwood-blue-300"></span>
              Logout
            </a>
          </nav>
        </div>
        <div class="flex-shrink-0 flex border-t border-oakwood-blue-700 p-4">
          <a href="#" class="flex-shrink-0 group block">
            <div class="flex items-center">
              <div>
                <!-- <img class="inline-block h-10 w-10 rounded-full" :src="user.image" alt=""> -->
                <span class="inline-block h-9 w-9 rounded-full text-center fa fa-user bg-oakwood-blue-500 text-white p-2"></span>
              </div>
              <div class="ml-3">
                <p class="text-base leading-6 font-medium text-white">
                  {{ user.name }}
                </p>
                <router-link :to="{name: 'profile-edit'}" class="text-sm leading-5 font-medium text-oakwood-blue-300 group-hover:text-oakwood-blue-100 transition ease-in-out duration-150">
                  Edit profile
                </router-link>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="flex-shrink-0 w-14">
        <!-- Force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>


  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col h-0 flex-1 bg-oakwood-blue-800">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div class="flex items-center flex-shrink-0 px-4">
            <img class="w-full" src="@/assets/images/oakwood-homes-logo.svg" alt="">
          </div>
          <nav class="mt-5 flex-1 px-2 bg-oakwood-blue-800 space-y-1">
            <router-link v-for="link,i in routes" :to="{name: link.name}" :key="`m-d-${i}`" class="group flex items-center p-3 text-sm leading-5 font-medium rounded-md hover:text-white focus:outline-none  transition ease-in-out duration-150" :class="link.name === this.$route.name || link.name === this.$route?.meta?.parent ? 'bg-oakwood-blue-900 text-white' : 'text-oakwood-blue-400 hover:bg-oakwood-blue-700 focus:bg-oakwood-blue-700'">
              <span class="mr-3 text-xl text-oakwood-blue-400  group-focus:text-oakwood-blue-300 transition ease-in-out duration-150 far" :class="{[link.meta.icon] : true, 'group-hover:text-oakwood-blue-300' : link.name !== this.$route.name && link.name !== this.$route?.meta?.parent}"></span>
              {{ link.meta.title }}
            </router-link>
            <a @click.prevent="this.logout()" class="group flex items-center p-3 text-sm leading-5 font-medium rounded-md hover:text-white focus:outline-none transition ease-in-out duration-150 text-oakwood-blue-400 hover:bg-oakwood-blue-700 focus:bg-oakwood-blue-700 cursor-pointer">
              <span class="mr-3 text-xl text-oakwood-blue-400  group-focus:text-oakwood-blue-300 transition ease-in-out duration-150 far fa-right-from-bracket group-hover:text-oakwood-blue-300"></span>
              Logout
            </a>
          </nav>
        </div>
        <div class="flex-shrink-0 flex border-t border-oakwood-blue-700 p-4">
          <a href="#" class="flex-shrink-0 w-full group block">
            <div class="flex items-center">
              <div>
                <!-- <img class="inline-block h-9 w-9 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""> -->
                <span class="inline-block h-9 w-9 rounded-full text-center fa fa-user bg-oakwood-blue-500 text-white p-2"></span>
              </div>
              <div class="ml-3">
                <p class="text-sm leading-5 font-medium text-white">
                  {{ user.name }}
                </p>
                <router-link :to="{name: 'profile-edit'}" class="text-xs leading-4 font-medium text-oakwood-blue-300 group-hover:text-oakwood-blue-100 transition ease-in-out duration-150">
                  Edit profile
                </router-link>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useAuthStore } from '@/stores/auth';
  import { mapActions, mapState } from 'pinia';

  export default {
    name: "MenuNavigation",
    props: ["isOpen"],
    data() {
      return {
      }
    },

    methods: {
      ...mapActions(useAuthStore, ['logout']),
      toggleSidebar() {
        this.$emit("update:isOpen", !this.isOpen);
      },
    },
    computed: {
      ...mapState(useAuthStore, ['user']),
      routes() {
        return this.$router.getRoutes()
          .filter(route => {
            return route?.meta?.menu === true && this.user.level >= route.meta.acl
          })
          .sort((a,b) => a.meta?.order > b.meta?.order ? 1 : -1);
      }
    },
    watch: {
      '$route' : {
        handler() {
          this.$emit("update:isOpen", false);
        },
        deep: true
      }
    }
  }
</script>
