import { defineStore } from 'pinia';
import axios from 'axios';

const auth = axios.create({
    baseURL: import.meta.env.VITE_APP_ENDPOINT
});
auth.defaults.withCredentials = true;
auth.defaults.withXSRFToken = true;

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            authenticated: false,
            user: {},
            error: null
        }
    },
    getters: {
        getUser: (state) => state.user,
        level: (state) => state.user.level
    },
    actions: {
        csrf() {
            return auth.get('sanctum/csrf-cookie');
        },
        login(email, password) {
            this.csrf().then(() => {
                auth.post('login', {email,password})
                .then(() => {
                    this.check();
                    this.error = null;
                })
                .catch(error => {
                    console.error(error);
                    this.error = error.response.data.message;
                })
            })
            
        },
        logout() {
            auth.post('logout')
                .then(() => {
                    this.authenticated = false;
                    this.user = {};
                    this.error = "Logged out successfully"
                })
                .catch(error => {
                    console.error(error);
                })
        },
        check() {
            return auth.get('api/user')
                .then(response => {
                    this.authenticated = true;
                    this.user = response.data;
                    this.error = null;
                })
                .catch(error => {
                    if(this.authenticated === true) {
                        this.error = error.response.data.message;
                    }
                    this.authenticated = false;
                    this.user = {};
                })
        }
    }
})