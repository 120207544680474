<script>
import { GlobalPolicy } from '@/policies/global';
import { FormButton, SelectField, TextInput } from '../form';
import Media from '@/models/media';

export default {
    setup() {
        return {
            GlobalPolicy,
            Media
        }
    },
    data() {
        return {
            editing: false
        }
    },
    methods: {
        edit() {
            this.editing = JSON.parse(JSON.stringify(this.item));
        },
        cancel() {
            this.editing = false;
        },
        save() {
            if(!this.valid) {
                return
            }
            this.$emit('update', this.editing);
            this.cancel();
        }
    },
    computed: {
        valid() {
            return this.editing.alt.length > 0 &&
                this.editing.caption.length > 0 &&
                this.editing.meta.category !== null &&
                (
                    this.editing.meta.category !== 'home' ||
                    (
                        this.editing.meta.category === 'home' &&
                        this.editing.meta.position !== null
                    )
                )
        }
    },
    emits: ['update'],
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    components: {
        FormButton,
        TextInput,
        SelectField
    },
    created() {
        if(this.$route.path.includes('/edit') && GlobalPolicy.edit()) {
            this.edit();
        }
    }
}
</script>

<template>
    <div class="sticky top-0 bg-white flex flex-col lg:flex-row justify-between items-center py-4 -my-4">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Info</h2>
        <nav v-if="GlobalPolicy.edit()" class="flex items-end gap-2">
            <FormButton @click="edit()" v-if="!editing" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-pencil">
                Edit
            </FormButton>
            <template v-else>
                <FormButton @click="cancel()" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300" icon="fa-cancel">
                    Cancel
                </FormButton>
                <FormButton :disabled="!valid" @click="save" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-save">
                    Save
                </FormButton>
            </template>
        </nav>
    </div>
    <dl class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-if="!editing">
        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Public Title</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ item.alt }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Public Caption</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ item.caption }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Keywords</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ item.meta.keywords }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Category</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ item.category?.label }}</dd>

        <template v-if="item.meta.category == 'home'">
            <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">View</dt>
            <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ item.meta.position }}</dd>
        </template>
    </dl>

    <form @submit="save" class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-else>
        <legend class="sr-only">Media Item Properties</legend>

        <label for="editing.alt" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">
            Public Title <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
        </label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <TextInput placeholder="Like 'Kitchen' or 'Elevation A'" id="editing.alt" v-model="editing.alt" required />
        </div>

        <label for="editing.caption" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">
            Public Caption <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
        </label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <textarea class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400 min-h-48" id="editing.caption" v-model="editing.caption" required></textarea>
        </div>

        <label for="editing.meta.keywords" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">
            Keywords
        </label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <textarea class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400 min-h-48" id="editing.meta.keywords" v-model="editing.meta.keywords"></textarea>
        </div>

        <label for="editing.meta.category" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">
            Category <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
        </label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <SelectField id="editing.meta.category" v-model="editing.meta.category" required>
                <option :value="null">Please Select...</option>
                <option v-for="category, k in Media.categories" :key="`category-${k}`" :value="category.value">
                    {{ category.label }}
                </option>
            </SelectField>
        </div>
        <template v-if="editing.meta.category === 'home'">
            <label for="editing.meta.position" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">
                View <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
            </label>
            <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
                <SelectField id="editing.meta.position" v-model="editing.meta.position" required>
                    <option :value="null">Please Select...</option>
                    <option v-for="position, k in Media.positions" :key="`position-${k}`" :value="position">
                        {{ position }}
                    </option>
                </SelectField>
            </div>
        </template>
    </form>
</template>