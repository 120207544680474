export default class UserLevels {
    static levels = [
        {
            value: 0,
            label: 'Read-Only Access'
        },
        {
            value: 1,
            label: 'Edit Access'
        },
        {
            value: 2,
            label: 'Admin'
        }
    ]

    static label(value) {
        return UserLevels.levels.find(level => level.value === value)?.label;
    }
}