<template>
  <LoadingView v-if="isLoading" />
  <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700 mb-8">Floorplans</h1>
  <div class="flex flex-col">
    <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4">
      <div class="mb-4 flex-initial">
        <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Search by Keyword</label>
        <input v-model="query.search" placeholder="Search floorplans by keyword" id="search" name="search" type="text"
          autocomplete="off"
          class="p-4 mt-1 sm:w-72 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm border-oakwood-gray-300 rounded-md h-8">
      </div>
      <div class="mb-4 flex-initial">
        <SelectMultipleField v-model="query.status" label="Published" :options="statuses" empty="All Statuses" />
      </div>
      <div v-if="communities.length" class="mb-4 flex-initial">
        <SelectField v-model="query.community" label="Community">
          <option :value="null">All Communities</option>
          <option v-for="community, i in communities" :key="`callout-${i}`" :value="community">{{
            community.meta?.info?.name ?? community.kova.WebGroupName }}</option>
        </SelectField>
      </div>
      <div v-if="collections.length > 1" class="mb-4 flex-initial">
        <SelectField v-model="query.collection" label="Collection">
          <option :value="null">All Collections</option>
          <option v-for="collection, i in collections" :key="`callout-${i}`" :value="collection.community_rid">{{
            collection.kova?.MarketingName }}</option>
        </SelectField>
      </div>
      <div v-if="hasFilters" class="mb-4 flex-initial sm:mt-5">
        <FormButton @click="clear" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
      </div>
    </div>

    <PaginationTable v-if="!isLoading" :data="filtered">
      <template #headers>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
          Name
        </th>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
          Collection
        </th>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
          Base Price
        </th>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider hidden sm:table-cell">
          Sales Status
        </th>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider hidden sm:table-cell">
          Published
        </th>
      </template>
      <template #columns="{ row }">
        <td class="col-span-3 order-1 block sm:table-cell">
          <router-link class="block px-6 sm:py-4 whitespace-no-wrap"
            :to="{ name: 'floorplan-show', params: { id: row.id } }">
            <div class="flex items-center">
              <div class="flex-shrink-0 rounded-full overflow-hidden">
                <img v-if="row.image" class="h-10 w-10 aspect-square object-cover" :src="row.image" alt="">
                <i v-else class="fa-duotone fa-house fa-2xl"></i>
              </div>
              <div class="ml-4">
                <div class="text-sm leading-5 font-medium text-oakwood-gray-900">
                  {{ row.meta.info?.name || row.kova?.MarketingName }}
                  <span v-if="row.status == 'new'"
                    class="bg-oakwood-gray-50 text-oakwood-blue-800 px-2 rounded-full inline-block text-xs leading-5 ml-1">New</span>
                </div>
                <div class="text-sm leading-5 text-oakwood-gray-500">
                  {{ getCollection(row.collection_id) }}
                </div>
              </div>
            </div>
          </router-link>
        </td>
        <td class="block order-2 col-span-2 sm:table-cell">
          <router-link class="block pl-20 pr-6 pb-4 sm:px-6 sm:py-4 whitespace-no-wrap"
            :to="{ name: 'floorplan-show', params: { id: row.id } }">
            <div class="text-sm leading-5 text-oakwood-gray-900">
              {{ row.collectionName }}
            </div>
          </router-link>
        </td>
        <td class="block order-2 col-span-2 sm:table-cell">
          <router-link class="block pl-20 pr-6 pb-4 sm:px-6 sm:py-4 whitespace-no-wrap"
            :to="{ name: 'floorplan-show', params: { id: row.id } }">
            <div class="text-sm leading-5 text-oakwood-gray-900">
              {{ formatToCurrency(row.meta?.info?.price?.min || row.kova?.Price) }}
            </div>
          </router-link>
        </td>
        <td class="block order-0 col-span-4 sm:table-cell">
          <router-link class="block px-6 pt-4 pb-2 sm:py-4 whitespace-no-wrap"
            :to="{ name: 'floorplan-show', params: { id: row.id } }">
            <span
              class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-oakwood-green-100 text-oakwood-green-800"
              :class="['Internal', 'Off Market', 'Sold Out', 'N/S'].includes(row.kova.CustomFields.TextCommModelStatus1) ? 'bg-oakwood-red-100 text-oakwood-red-800' : 'bg-oakwood-green-100 text-oakwood-green-800'">
              {{ floorplanStatuses[row.kova.CustomFields.TextCommModelStatus1] }}
            </span>
          </router-link>
        </td>
        <td
          class="sm:px-6 sm:py-4 whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block order-1 sm:table-cell">
          <div class="flex items-center gap-2">
            <ToggleButton v-if="GlobalPolicy.edit()" :id="`item-${row.id}-status`" @changed="save(row)" :checked="row.status == 'active'" 
              :label="(row.status == 'active' && 'Published') || (row.status == 'unpublished' && 'Unpublished') || row.status == 'new' && 'New'"/>
              <template v-else>
                <p title="Published" class="text-oakwood-blue fas fa-square-check text-xl" v-if="row.status == 'active'"></p>
                <p title="Unpublished" class="text-oakwood-gray-400 fas fa-square-x text-xl" v-else></p>
              </template>
          </div>
        </td>
      </template>
    </PaginationTable>
  </div>

</template>

<script>
import axios from "axios";
import { formatToCurrency } from '@/helpers/numberHelpers';
import LoadingView from '@/components/LoadingView.vue';
import { ToggleButton, SelectField, SelectMultipleField, FormButton } from '@/components/form';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useFloorplanStore } from "@/stores/floorplans";
import { statuses, floorplan } from '@/helpers/globals';
import PaginationTable from "@/components/PaginationTable.vue";
import { GlobalPolicy } from "@/policies/global";

export default {
  setup() {
        return {
            GlobalPolicy
        }
    },
  data() {
    return {
      floorplans: [],
      communities: [],
      limit: 20,
      isLoading: true,
      statuses,
      floorplanStatuses: floorplan.ModelStatus
    }
  },
  methods: {
    ...mapActions(useFloorplanStore, ['setPage', 'clear']),
    index() {
      axios.get(`floorplans`)
        .then(res => {
          this.floorplans = res.data;
          this.isLoading = false;
        })
        .catch(e => {
          throw e;
        });
    },
    indexCommunities() {
      axios
        .get(`divisions/communities`)
        .then((res) => {
          let collection_rids = new Set(this.floorplans.map(floorplan => floorplan.kova.CommunityRID));
          this.communities = res.data
            .map(community => {
              community.collections = community.collections.filter(collection => collection.status != 'inactive' || collection_rids.has(collection.community_rid));
              return community;
            })
            .filter(community => community.status != 'inactive' && community.collections.length > 0)
            .sort((a, b) => (a.meta?.info?.name || a.kova?.WebGroupName) > (b.meta?.info?.name || b.kova?.WebGroupName) ? 1 : -1);
          this.isLoading = false;
        })
        .catch((e) => {
          throw e;
        });
    },
    formatToCurrency,
    save(item) {
      item.status = item.status != 'active' ? 'active' : 'inactive';
      axios.patch(`floorplans/${item.id}`, item)
        .then(res => {
          if (res.data?.id) {
            this.index();
          }
        })
        .catch(e => {
          throw e;
        });
    },
    getCollection() {
      return "";
    }
  },
  computed: {
    ...mapState(useFloorplanStore, ['hasFilters']),
    ...mapWritableState(useFloorplanStore, ['query', 'page']),
    filtered() {
      let results = this.floorplans;
      if (!this.query.status.includes(null)) {
        results = results.filter(item => this.query.status.includes(item.status));
      }

      if (this.query.collection !== null) results = results.filter(item => item.kova.CommunityRID == this.query.collection);

      if (this.query.community !== null) results = results.filter(item => this.query.community.collections.map(collection => collection.community_rid).includes(item.kova.CommunityRID));

      if (this.query.search != '') {
        const regex = new RegExp(this.query.search, 'i');
        results = results.filter(item => regex.test(JSON.stringify(item)));
      }
      return results;
    },
    paginated() {
      return this.filtered.slice(this.from, this.to);
    },
    from() {
      return (this.page * this.limit) - this.limit;
    },
    to() {
      let to = this.page * this.limit;
      return to <= this.filtered.length ? to : this.filtered.length;
    },
    max() {
      return Math.ceil(this.filtered.length / this.limit);
    },
    collections() {
      if (this.communities.length == 0 || this.query.community === null) return [];
      return this.query.community.collections;
    },
  },
  watch: {
    query: {
      handler(newVal, oldVal) {
        if (newVal.community != oldVal.community)
          this.query.collection = null;
        this.page = 1;
      },
      deep: true
    }
  },
  created() {
    this.index();
    this.indexCommunities();
  },
  components: { LoadingView, ToggleButton, SelectField, SelectMultipleField, FormButton, PaginationTable },
}
</script>

@/components/form