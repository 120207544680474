<script>
export default {
    head() {
        return {
            templateParams: {
                tab: this.selectedTab?.title
            },
            titleTemplate: '%s %separator %tab'
        }
    },
    data() {
        return {
            hint: {
                show: false,
                left: false,
                right: true
            },
            timeout: null,
            leading: false
        }
    },
    props: {
        tabs: {
            type: Array,
            require: true
        },
        route: {
            type: String,
            require: true
        }
    },
    computed: {
        selectedTab() {
            return this.tabs.find(tab => tab.name === this.$route.params?.tab) || this.tabs[0];
        }
    },
    methods: {
        setScroller() {
            if(!this.leading) {
                this.hint = {
                    show: (this.$refs?.tabwrapper?.scrollWidth ?? 0) < (this.$refs?.tabs?.scrollWidth ?? 0),
                    right: this.$refs?.tabs?.scrollLeft !== (this.$refs?.tabs?.scrollWidth ?? 0) - (this.$refs?.tabwrapper?.scrollWidth ?? 0),
                    left: this.$refs?.tabs?.scrollLeft !== 0
                }
                this.leading = true
            }
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.leading = false
            }, 10);
        }
    },
    created() {
        window.addEventListener('resize', this.setScroller);
        this.$nextTick(() => {
            this.$refs.tabs.addEventListener('scroll', this.setScroller)
            this.setScroller();
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setScroller);
        this.$refs.tabs.removeEventListener('scroll', this.setScroller)
    },
}
</script>

<style>
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
</style>

<template>
    <div class="block border-b border-oakwood-gray-200">
        <nav role="tablist" class="relative hint" ref="tabwrapper">
            <ul class="flex -mb-px px-6 gap-5 sm:gap-6 overflow-x-auto no-scrollbar" ref="tabs">
                <li :aria-controls="`panel-${tab.name}`" v-for="tab, i in tabs" :key="`panel-${i}`">
                    <component :is="selectedTab?.name === tab.name ? 'p' : 'router-link'" 
                    :class="selectedTab?.name === tab.name ? 'cursor-default border-oakwood-blue-500 border-b-2 text-oakwood-blue-600' : 'cursor-pointer text-oakwood-gray-500 hover:text-oakwood-gray-700 border-transparent hover:border-oakwood-gray-300'"
                    class="group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none"
                    :to="{name: this.route, params: {tab: tab?.name}}">
                        <span class="far mr-2 text-lg" :class="tab?.icon"></span>
                        <span class="hidden sm:inline whitespace-nowrap">{{ tab?.title }}</span>
                    </component>
                </li>
            </ul>
            <div aria-hidden="true" :class="[hint.show && hint.left ? 'from-0%' : 'from-50% via-50%']" class="flex items-center justify-start px-2 absolute w-12 top-0 left-0 bottom-1 z-10 from-transparent via-white to-white bg-gradient-to-l pointer-events-none transition-all duration-50">
            </div>
            <div aria-hidden="true" :class="[hint.show && hint.right ? 'from-0%' : 'from-50% via-50%']" class="flex items-center justify-end px-2 absolute w-12 top-0 right-0 bottom-1 z-10 from-transparent via-white to-white bg-gradient-to-r pointer-events-none transition-all duration-50">
            </div>
        </nav>
    </div>
    <section :id="`panel-${selectedTab?.name}`" role="tabpanel" class="py-8 px-5 sm:px-6">
        <slot :tab="selectedTab"></slot>
    </section>
</template>