import { useAuthStore } from "@/stores/auth";

export class GlobalPolicy {
    static view() {
        return useAuthStore().level >= 0;
    }

    static create() {
        return useAuthStore().level >= 1;
    }

    static edit() {
        return useAuthStore().level >= 1;
    }

    static delete() {
        return useAuthStore().level >= 1;
    }
}