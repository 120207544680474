<script>
import { FormButton } from "@/components/form";
import LoadingView from "@/components/LoadingView.vue";
import { AttachmentsPane, InfoPane } from "@/components/media";
import { LogPane } from "@/components/shared";
import TabPanes from "@/components/TabPanes.vue";
import Media from "@/models/media";
import { GlobalPolicy } from "@/policies/global";
import { useAuthStore } from "@/stores/auth";
import axios from "axios";
import { mapState } from "pinia";

let tabs = [
    {
        name: 'info',
        icon: 'fa-circle-info',
        title: 'General Information'
    },
    {
        name: 'attachments',
        icon: 'fa-images',
        title: 'Attached Locations'
    },
    {
        name: 'logs',
        icon: 'fa-timer',
        title: 'History'
    }
];
export default {
    head() {
        return {
            title: () => `${this.$route.meta.title} %separator ${this.media?.alt ?? 'Untitled'}`
        }
    },
    setup() {
        return {
            GlobalPolicy
        }
    },
    data() {
        return {
            media: new Media({id: this.$route.params.id}),
            isLoading: true,
            tabs: []
        }
    },
    methods: {
        show() {
            axios.get(`media/${this.media.id}`)
                .then(res => {
                    this.media = new Media(res.data);
                    this.isLoading = false;
                })
                .catch(err => {
                    if(err.response.status === 404) 
                        handle404(this, err.response.data.message);
                });
        },
        save() {
            this.isLoading = true;
            axios.patch(`media/${this.media.id}`, this.media)
                .then(res => {
                    this.isLoading = false;
                })
                .catch(e => {
                    throw e;
                });
        },
        prepare(value) {
            this.media = new Media(value);
            this.save();
        }
    },
    computed: {
        ...mapState(useAuthStore, ['user'])
    },
    created() {
        this.show();
        this.tabs = tabs.filter(tab => {
            if(this.user.level < 2 && (tab.name == 'debugger' || tab.name == 'logs')) return false;
            return true;
        });
    },
    components: {
        LoadingView,
        FormButton,
        TabPanes,
        AttachmentsPane,
        InfoPane,
        LogPane,
    }
}
</script>

<template>
    <LoadingView v-if="isLoading" />
    <div v-else class="bg-white shadow sm:rounded">
        <nav class="flex flex-none gap-4 justify-between items-center order-1 lg:order-2 rounded-t-lg bg-green-200">
            <div class="py-4 px-5 flex-none">
                <FormButton 
                    class="shadow-none bg-green-300 opacity-80 hover:opacity-100 transition-opacity delay-75" 
                    icon="fa-chevron-left" 
                    :to="{ name: 'media-list' }">
                    Return to List
                </FormButton>
            </div>
        </nav>
        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-start gap-8">
            <div class="flex flex-col sm:flex-row gap-5 sm:gap-6 flex-1">
                <div class="flex flex-col gap-5 sm:gap-6 flex-1 justify-between order-2 lg:order-1">
                    <div class="flex flex-col lg:flex-row gap-5 justify-between flex-none">

                        <div class="flex-1 flex flex-col gap-2 lg:gap-0 order-2 lg:order-1">
                            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                                {{ media.alt }}
                            </h1>
                        </div>
                    </div>
                    <section class="grid grid-cols-1 md:grid-cols-2 border border-oakwood-gray-200 rounded">
                        <h2 class="sr-only">Basic Information</h2>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far" :class="media.icon"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Type</dt>
                                <dd class="font-semibold">{{ media.type }}</dd>
                            </dl>
                        </aside>


                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-user"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Creator</dt>
                                <dd class="font-semibold">{{ media.creator?.name }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-calendar-plus"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Created Date</dt>
                                <dd class="font-semibold">{{ media.date }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-floppy-disk"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">File Size</dt>
                                <dd class="font-semibold">{{ media.size }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-input-text"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Filename</dt>
                                <dd class="font-semibold">{{ media.filename }}</dd>
                            </dl>
                        </aside>

                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="lg:text-2xl bg-oakwood-blue-500 p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-arrows-maximize"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Dimensions</dt>
                                <dd class="font-semibold">{{ media.dimensions.width }} x {{ media.dimensions.height }}</dd>
                            </dl>
                        </aside>
                    </section>

                </div>
                <div class="w-full lg:w-1/3 flex order-1 lg:order-2">
                    <img v-if="media.url" class="object-cover"
                        :src="media.url" :alt="media.caption"
                        :title="media.alt">
                    <div v-else class="text-5xl bg-oakwood-blue-300 text-white p-8 flex items-center justify-center w-full">
                        <span class="far fa-house"></span>
                    </div>
                </div>

            </div>
        </header>
        <TabPanes route="media-tab" :tabs="tabs" v-slot="{tab}">
            <InfoPane :item="media" @update="prepare" v-if="tab?.name === 'info'" />
            <AttachmentsPane :id="media.id" v-if="tab?.name === 'attachments'" />
            <LogPane context="media" v-if="tab?.name === 'logs'" />
        </TabPanes>
    </div>
</template>