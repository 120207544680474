<template>
  <LoadingView v-if="isLoading" />
  <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700 mb-8">Available Homes</h1>
  <div class="flex flex-col">
    <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4">
      <div class="mb-4 flex-initial">
        <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Search by Keyword</label>
        <input v-model="query.search" placeholder="Search available homes by keyword" id="search" name="search"
          type="text" autocomplete="off"
          class="p-4 mt-1 sm:w-72 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm border-oakwood-gray-300 rounded-md h-8">
      </div>
      <div class="mb-4 flex-initial">
        <SelectMultipleField v-model="query.status" label="Published" :options="statuses" empty="All Statuses"/>
      </div>
      <div v-if="states.length" class="mb-4 flex-initial">
        <SelectField v-model="query.state" label="States">
            <option :value="null">All States</option>
            <option v-for="st,i in states" :key="`callout-${i}`" :value="st">{{ st }}</option>
        </SelectField>
      </div>
      <div v-if="floorplans.length" class="mb-4 flex-initial">
        <SelectField v-model="query.floorplan" label="Floorplan">
            <option :value="null">All Floorplans</option>
            <option v-for="floorplan,i in floorplans" :key="`callout-${i}`" :value="floorplan">{{ floorplan }}</option>
        </SelectField>
      </div>
      <div v-if="communities.length" class="mb-4 flex-initial">
        <SelectField v-model="query.community" label="Community">
            <option :value="null">All Communities</option>
            <option v-for="community,i in communities" :key="`callout-${i}`" :value="community">{{ community.meta?.info?.name || community.kova?.WebGroupName }}</option>
        </SelectField>
      </div>
      <div v-if="collections.length > 1" class="mb-4 flex-initial">
        <SelectField v-model="query.collection" label="Collection">
            <option :value="null">All Collections</option>
            <option v-for="collection,i in collections" :key="`callout-${i}`" :value="collection.community_rid">{{ collection.kova?.MarketingName }}</option>
        </SelectField>
      </div>
      <div v-if="hasFilters" class="mb-4 flex-initial sm:mt-5">
        <FormButton @click="clear" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
      </div>
    </div>

    <PaginationTable v-if="!isLoading" :data="filtered">
      <template #headers>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
          Address
        </th>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
          Floorplan
        </th>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
          Collection
        </th>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
          Price
        </th>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider hidden sm:table-cell">
          Lot Status
        </th>
        <th
          class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider hidden sm:table-cell">
          Published
        </th>
      </template>
      <template #columns="{row}">
        <td class="block col-span-3 order-1 sm:table-cell">
          <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'available-homes-show', params: { id: row.id } }">
            <div class="flex items-center">
              <div class="flex-shrink-0 rounded-full overflow-hidden">
                <img v-if="row.image" class="h-10 w-10 aspect-square object-cover"
                  :src="row.image" alt="">
                <i v-else class="fa-duotone fa-house fa-2xl"></i>
              </div>
              <div class="ml-4">
                <div class="text-sm leading-5 font-medium text-oakwood-gray-900">
                  {{ row.kova?.Lot?.StreetAddress }}, {{ row.kova?.Lot?.City }}, {{ row.kova?.Lot?.State }}
                  {{ row.kova?.Lot?.ZipCode }} 
                  <span v-if="row.status == 'new'" class="bg-oakwood-gray-50 text-oakwood-blue-800 px-2 rounded-full inline-block text-xs leading-5 ml-1">New</span>
                </div>
                <div class="text-sm leading-5 text-oakwood-gray-500">
                  {{ row.kova?.Name }}
                </div>
              </div>
            </div>
          </router-link>
        </td>
        <td class="pl-14 pr-6 sm:px-6 block col-span-4 order-2 sm:table-cell">
          <router-link class="block py-4 whitespace-no-wrap" :to="{ name: 'available-homes-show', params: { id: row.id } }">
            <div class="text-sm leading-5 text-oakwood-gray-900">
              {{ row.floorplan_name }}
            </div>
          </router-link>
        </td>
        <td class="pl-14 pr-6 sm:px-6 block col-span-4 order-2 sm:table-cell">
          <router-link class="block py-4 whitespace-no-wrap" :to="{ name: 'available-homes-show', params: { id: row.id } }">
            <div class="text-sm leading-5 text-oakwood-gray-900">
              {{ row.collection_name }}
            </div>
          </router-link>
        </td>
        <td class="pl-14 pr-6 sm:px-6 block col-span-4 order-2 sm:table-cell">
          <router-link class="block py-4 whitespace-no-wrap" :to="{ name: 'available-homes-show', params: { id: row.id } }">
            <div class="text-sm leading-5 text-oakwood-gray-900">
              {{ formatToCurrency(row.meta?.info?.price || row.kova?.Price) }}
            </div>
          </router-link>
        </td>
        <td class="hidden sm:table-cell">
          <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'available-homes-show', params: { id: row.id } }">
            <span
              class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-oakwood-green-100 text-oakwood-green-800">
              {{ row.kova?.Lot?.Status }}
            </span>
          </router-link>
        </td>
        <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block order-1 sm:table-cell">
          <div class="flex items-center gap-2">
            <ToggleButton v-if="GlobalPolicy.edit()" :id="`item-${row.id}-status`" @changed="save(row)" :checked="row.status == 'active'" 
              :label="(row.status == 'active' && 'Published') || (row.status == 'unpublished' && 'Unpublished') || row.status == 'new' && 'New'"/>
            <template v-else>
              <p title="Published" class="text-oakwood-blue fas fa-square-check text-xl" v-if="row.status == 'active'"></p>
              <p title="Unpublished" class="text-oakwood-gray-400 fas fa-square-x text-xl" v-else></p>
            </template>          </div>
        </td>
      </template>
    </PaginationTable>
  </div>

</template>

<script>
import axios from "axios";
import { formatToCurrency } from '@/helpers/numberHelpers';
import LoadingView from '@/components/LoadingView.vue';
import {ToggleButton,SelectField,SelectMultipleField,FormButton} from '@/components/form';
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useMarketHomeStore } from "@/stores/market_homes";
import PaginationTable from "@/components/PaginationTable.vue";
import { GlobalPolicy } from "@/policies/global";

export default {
  setup() {
      return {
          GlobalPolicy
      }
  },
  data() {
    return {
      salesOrders: [],
      communities: [],
      floorplans: [],
      isLoading: true,
      statuses: [
        {
          label : 'Published',
          value : 'active'
        },
        {
          label : 'Unpublished',
          value : 'inactive'
        },
        {
          label : 'New',
          value : 'new'
        }
      ]
    }
  },
  methods: {
    ...mapActions(useMarketHomeStore, ['setPage', 'clear']),
    index() {
      axios.get(`sales_orders`)
        .then(res => {
          this.salesOrders = res.data;
          this.isLoading = false;
          this.indexCommunities();
          this.indexFloorplans();
        })
        .catch(e => {
          throw e;
        });
    },
    indexCommunities() {
      axios
      .get(`divisions/communities`)
      .then((res) => {
        let collection_rids = new Set(this.salesOrders.map(salesOrder => salesOrder.kova.CommunityRID));
        this.communities = res.data
          .map(community => {
            community.collections = community.collections
              .filter(collection => collection.status != 'inactive' && collection_rids.has(collection.community_rid))
              .sort((a,b) => a.meta.info.name > b.meta.info.name ? 1 : -1);
            return community;
          })
          .filter(community => community.status != 'inactive' && community.collections.length > 0)
          .sort((a,b) => (a.meta?.info?.name || a.kova?.WebGroupName) > (b.meta?.info?.name || b.kova?.WebGroupName) ? 1 : -1);
        this.isLoading = false;
      })
      .catch((e) => {
        throw e;
      });
    },
    indexFloorplans() {
      axios
      .get(`floorplans`)
      .then((res) => {
        let available = [...new Set(this.salesOrders.map(home => home.kova.ModelRID))];        this.floorplans = [...new Set(res.data
          .filter(floorplan => available.includes(floorplan.kova.ModelRID))
          .map(floorplan => floorplan.meta.info.name)
        )];
      })
      .catch((e) => {
        throw e;
      });
    },
    formatToCurrency,
    save(item) {
      item.status = item.status != 'active' ? 'active' : 'inactive';
      axios.patch(`sales_orders/${item.id}`, item)
      .then(res => {
        if(res.data?.id) {
          this.index();
        }
      })
      .catch(e => {
        throw e;
      });
    }
  },
  computed: {
    ...mapState(useMarketHomeStore, ['hasFilters']),
    ...mapWritableState(useMarketHomeStore, ['query', 'page']),
    filtered() {
      let results = this.salesOrders;
      if (!this.query.status.includes(null)) {
          results = results.filter(item => this.query.status.includes(item.status));
        }
      if (this.query.state !== null) results = results.filter(item => item.kova.Lot.State == this.query.state);

      if (this.query.floorplan !== null) results = results.filter(item => item.floorplan_name == this.query.floorplan);

      if (this.query.collection !== null) results = results.filter(item => item.kova.CommunityRID == this.query.collection);

      if (this.query.community !== null) results = results.filter(item => this.query.community.collections.map(collection => collection.community_rid).includes(item.kova.CommunityRID));

      if (this.query.search != '') {
        const regex = new RegExp(this.query.search, 'i');
        results = results.filter(item => regex.test(JSON.stringify(item)));
      } 
      return results;
    },
    states() {
      if(this.salesOrders.length == 0) return [];
      return [...new Set(this.salesOrders.map((home) => home.kova.Lot.State))];
    },
    collections() {
      if(this.communities.length == 0 || this.query.community === null) return [];
      return this.query.community.collections;
    }
  },
  watch: {
      query: {
        handler(newVal, oldVal) {
          if(newVal.community != oldVal.community)
            this.query.collection = null;
          this.page = 1;
        },
        deep: true
      }
    },
  created() {
    this.index();
  },
  components: { 
    LoadingView, 
    ToggleButton, 
    SelectField, 
    SelectMultipleField, 
    FormButton, 
    PaginationTable 
  },
}
</script>