<template>
    <LoadingView v-if="isLoading" />
    <header class="flex justify-between items-center">
      <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700 mb-8">Users</h1>
      <FormButton :to="{name: 'user-add', }" icon="fa-add" class="bg-oakwood-blue-500 text-white">Add New User</FormButton>
    </header>

    <div class="flex flex-col">
      <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4">
        <div class="mb-4 flex-initial">
          <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Search by Keyword</label>
          <input v-model="query.search" placeholder="Search user names or emails by keyword" id="search" name="search"
            type="text" autocomplete="off"
            class="p-4 mt-1 sm:w-72 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm border-oakwood-gray-300 rounded-md h-8">
        </div>
        <div class="mb-4 flex-initial">
            <SelectField v-model="query.status" label="Status">
                <option :value="null">All Statuses</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
            </SelectField>
        </div>
        <div class="mb-4 flex-initial">
          <SelectField v-model="query.level" label="Access Level">
              <option :value="null">All Levels</option>
              <option v-for="level in UserLevels.levels" :key="`level-${level.value}`" :value="level.value">
                  {{ level.label }}
              </option>
          </SelectField>
        </div>
        <div v-if="hasFilters" class="mb-4 flex-initial sm:mt-5">
          <FormButton @click="reset" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
        </div>
      </div>

      <PaginationTable v-if="!isLoading" :data="filtered">
        <template #headers>
          <th
            class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
            Name
          </th>
          <th
            class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
            Email
          </th>
          <th
            class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
            Last Login
          </th>
          <th
            class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
            Level
          </th>
          <th
            class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
            Status
          </th>
        </template>
        <template #columns="{row}">
          <td class="col-span-3 order-1 block sm:table-cell">
            <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'user-edit', params: { id: row.id } }">
              <div class="flex items-center">
                <div class="flex-shrink-0 rounded-full overflow-hidden flex items-center justify-center bg-oakwood-blue-300 text-white h-10 w-10">
                  <img v-if="row.image" class="aspect-square object-cover"
                    :src="row.image" alt="">
                  <i v-else class="fas fa-user"></i>
                </div>
                <div class="ml-4">
                  <div class="text-sm leading-5 font-medium text-oakwood-gray-900">
                    {{ row.name }}
                  </div>
                </div>
              </div>
            </router-link>
          </td>
          <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 hidden sm:table-cell">
            <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'user-edit', params: { id: row.id } }">
                <p class="text-sm leading-5 text-oakwood-gray-900">
                    {{ row.email }}
                </p>
            </router-link>
          </td>
          <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 hidden sm:table-cell">
            <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'user-edit', params: { id: row.id } }">
                <p class="text-sm leading-5 text-oakwood-gray-900">
                    {{ new Intl.DateTimeFormat('en-us', {
                      month:'short', 
                      day:'numeric', 
                      year:'numeric', 
                      hour:'numeric', 
                      hour12:true, 
                      minute:'2-digit', 
                      second:'2-digit', 
                      timeZone:'America/Denver',
                      timeZoneName:'short'
                      }).format(new Date(row.last_login || row.updated_at)) }}
                </p>
            </router-link>
          </td>
          <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 hidden sm:table-cell">
            <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'user-edit', params: { id: row.id } }">
              <p
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-oakwood-green-100 text-oakwood-green-800">
                {{ UserLevels.label(row.level) }}
              </p>
            </router-link>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block order-1 sm:table-cell">
            <div class="flex items-center gap-2">
              <ToggleButton :id="`item-${row.id}-status`" @changed="(e) => save(e, row)" :checked="row.deleted_at === null" 
              :label="row.deleted_at === null ? 'Active' : 'Inactive'"/>
              <label class="sr-only" :for="`item-${row.id}-status`">{{ row.deleted_at === null ? 'Active' : 'Inactive' }}</label>
            </div>
          </td>
        </template>
      </PaginationTable>
    </div>

</template>

<script>
import axios from "axios";
import LoadingView from '@/components/LoadingView.vue';
import {ToggleButton,FormButton,SelectField} from '@/components/form';
import PaginationTable from "@/components/PaginationTable.vue";
import UserLevels from "@/enums/UserLevels";

const query = {
    search: null,
    status: 'active',
    level: null
}

export default {
  setup() {
        return {
            UserLevels
        }
    },
    data() {
        return {
            isLoading: true,
            query: {...query},
            page: 1,
            limit: 20,
            users: []
        }
    },
    components: {
        LoadingView, 
        FormButton, 
        ToggleButton, 
        SelectField, 
        PaginationTable
    },
    methods: {
        index() {
            axios.get('users')
                .then(resp => {
                    this.users = resp.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    console.error(error);
                })
        },
        save(e, item) {
          this.isLoading = true;
          if(e) { // The item should be restored
            axios.patch(`users/${item.id}/restore`)
              .then(() => {
                this.index();
              })
          } else { // The item should be destroyed
            axios.delete(`users/${item.id}`)
              .then(() => {
                this.index();
              })
          }
        },
        reset() {
            this.query = {...query};
        }
    },
    computed: {
        hasFilters() {
            return JSON.stringify(this.query) != JSON.stringify(query);
        },
        filtered() {
            let results = this.users;

            if (this.query.status !== null) {
                results = results.filter(item => {
                    if(this.query.status == 'active') 
                        return item.deleted_at === null;
                    return item.deleted_at !== null;
                })
            }

            if (this.query.level !== null) {
                results = results.filter(item => item.level == this.query.level);
            }
            
            if (this.query.search !== null) {
            const regex = new RegExp(this.query.search, 'i');
            results = results.filter(item => regex.test(item.name) || 
                regex.test(item.email)
                );
            } 
            return results;
        },
    },
    mounted() {
      this.index();
    }
}
</script>@/components/form