<template>
    <main class="flex flex-col items-center justify-center bg-oakwood-blue h-screen gap-8">
        <div class="max-w-md flex flex-col items-center px-4 gap-2 text-oakwood-blue-300">
            <img class="w-full" src="@/assets/images/oakwood-homes-logo.svg" alt="Oakwood Homes logo">
            <h1 class="text-xl">Enhanced Data Feed Manager</h1>
        </div>
        <div class="w-full max-w-md rounded bg-white p-6">
            <nav aria-label="Tabs" role="tablist" class="grid grid-cols-2 gap-2 items-center justify-center mb-4 pb-4 border-b border-b-oakwood-blue-300">
                <a tabindex="0" @keyup.prevent="(key) => { if(['Space','Enter'].includes(key.code)) current = k}" @click.prevent="current = k" v-for="(tab,k) in tabs" :key="`tab-control-${k}`" 
                :aria-controls="`tabpanel-${k}`" 
                :class="[current === k ? 'bg-oakwood-blue-100 text-oakwood-blue-700 cursor-default' : 'text-oakwood-blue-500 hover:text-oakwood-blue-700 cursor-pointer', 'rounded-md px-3 py-2 text-sm font-medium text-center']" 
                role="tab" :aria-selected="current === k"><span class="far fa-lock pr-1"></span>{{ tab }}</a>
            </nav>
            <section id="tabpanel-0" :class="{'hidden' : current !== 0}" class="flex flex-col gap-4" role="tabpanel">
                <h2 class="text-sm text-oakwood-gray-700 sr-only">
                    Login with other system credentials.
                </h2>
                <div>
                    <FormButton :href="azure" class="bg-oakwood-blue-800 w-full justify-center text-white"
                        icon="fa-brands fa-microsoft">Login with Microsoft 365</FormButton>
                </div>
            </section>
            <section id="tabpanel-1" :class="{'hidden' : current !== 1}" class="flex flex-col gap-4" role="tabpanel">
                <form @submit.prevent="login(email, password)" class="flex flex-col gap-4">
                    <div v-if="error" class="bg-oakwood-red-50 text-oakwood-red-600 border border-oakwood-red-600 p-4">
                        {{ error }}
                    </div>
                    <h2 class="text-sm text-oakwood-gray-700 sr-only">
                        Login using a username and password.
                    </h2>
                    <div class="grid grid-cols-4 items-center">
                        <label for="email" class="text-sm">Email</label>
                        <input class="col-span-3 bg-oakwood-gray-50 border border-oakwood-gray-300 px-2 py-1 rounded-sm"
                            type="text" v-model="email" id="email">
                    </div>
                    <div class="grid grid-cols-4 items-center">
                        <label for="password" class="text-sm">Password</label>
                        <input class="col-span-3 bg-oakwood-gray-50 border border-oakwood-gray-300 px-2 py-1 rounded-sm"
                            type="password" v-model="password" id="password">
                    </div>
                    <div>
                        <FormButton type="submit" class="bg-oakwood-blue-500 w-full justify-center text-white" icon="fa-right-to-bracket">Direct Login</FormButton>
                    </div>


                </form>
            </section>
        </div>
    </main>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import { mapActions, mapState } from 'pinia';
import { FormButton } from '@/components/form';

export default {
    head() {
        return {
            title: () => 'Login',
        }
    },
    data() {
        return {
            email: null,
            password: null,
            azure: `${import.meta.env.VITE_APP_ENDPOINT}auth/azure/redirect?url=${btoa(window.location.href)}`,
            current: 0,
            tabs: [
                'Single Sign-On',
                'Direct Login',
            ]
        }
    },
    methods: {
        ...mapActions(useAuthStore, ['login'])
    },
    computed: {
        ...mapState(useAuthStore, ['error'])
    },
    components: {
        FormButton
    }
}
</script>@/components/form