import { formatToNumber, numberFromBytes } from "@/helpers/numberHelpers"

export default class Media {

    id = null
    type = null
    filename = null
    filesize = 0
    path = null
    alt = null
    caption = null
    meta = {
        keywords: null,
        position: null,
        category: 'home',
        dimensions: {
            width: 0,
            height: 0
        }
    }
    creator = {}
    created_at = null
    updated_at = null
    deleted_at = null
    message = null
    saving = false
    url = null

    constructor(data) {
        this.id = data?.id ?? this.id
        this.type = data?.type ?? this.type
        this.filename = data?.filename ?? this.filename
        this.filesize = data?.filesize ?? this.filesize
        this.path = data?.path ?? this.path
        this.alt = data?.alt ?? this.alt
        this.caption = data?.caption ?? this.caption
        this.meta = {
            keywords: data?.meta?.keywords ?? this.meta.keywords,
            position: data?.meta?.position ?? this.meta.position,
            category: data?.meta?.category ?? this.meta.category,
            dimensions: {
                width: data?.meta?.dimensions?.width ?? this.meta.dimensions.width,
                height: data?.meta?.dimensions?.height ?? this.meta.dimensions.height
            }
        }
        this.creator = data?.creator ?? this.creator
        this.created_at = data?.created_at ?? this.created_at
        this.updated_at = data?.updated_at ?? this.updated_at
        this.deleted_at = data?.deleted_at ?? this.deleted_at
        this.message = data?.message ?? this.message
        this.saving = data?.saving ?? this.saving
        this.url = data?.url ?? this.url
        
        return this
    }

    get date() {
        return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true}).format(new Date(this.created_at))
    }

    get size() {
        return numberFromBytes(this.filesize)
    }

    get dimensions() {
        return {
            width: formatToNumber(this.meta.dimensions.width),
            height: formatToNumber(this.meta.dimensions.height)
        }
    }

    get icon() {
        switch(this.type) {
            case 'image/jpeg' :
                return 'fa-file-jpg'
            case 'image/png' :
                return 'fa-file-png'
            default :
                return 'fa-file'
        }
    }

    get category() {
        return Media.categories.find(category => category.value === this.meta.category)
    }

    static categories = [
        {
            value: 'home',
            label: 'Home'
        },
        {
            value: 'community',
            label: 'Community'
        }
    ];

    static positions = [
        'Elevation',
        'Floorplan',
        'Interior'
    ]
}