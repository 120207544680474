<template>
  <LoadingView v-if="isLoading" />
  <div class="flex flex-col">
    <header
      class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 sticky top-0 z-0 bg-white flex justify-between items-center gap-8"
    >
      <div>
        <h1 class="text-lg leading-6 font-medium text-oakwood-blue-600">
          Media Manager
        </h1>
        <p class="mt-1 max-w-2xl text-sm leading-5 text-oakwood-gray-500">
          Manage the available attachments.
        </p>
      </div>
      <nav class="flex flex-shrink-0 gap-4">
        <button
          v-if="GlobalPolicy.edit()"
          @click="() => {open = !open}"
          class="inline-flex items-center rounded-md bg-oakwood-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-oakwood-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-oakwood-blue-600"
        >
          <span class="fa fa-file-upload mr-2"></span>Add New media
        </button>
      </nav>
    </header>
    <MediaBrowser :media="media" @close="media => {this.media = media}"/>

    <AttachmentsUpload :open="open" @close="close" @save="refresh" />
  </div>
</template>

<script>
import axios from "axios";
import LoadingView from "@/components/LoadingView.vue";
import MediaBrowser from "@/components/MediaBrowser.vue";
import AttachmentsUpload from '@/components/AttachmentsUpload.vue';
import { GlobalPolicy } from "@/policies/global";

export default {
  setup() {
      return {
          GlobalPolicy
      }
  },
  emits: ['close'],
  data() {
    return {
      isLoading: true,
      media: [],
      open: false
    };
  },
  methods: {
    index() {
      axios
      .get(`media`)
      .then((res) => {
        this.media = res.data;
        this.isLoading = false;
      })
      .catch((e) => {
        throw e;
      });
    },
    close() {
      this.open = false;
    },
    refresh() {
      this.index();
      this.close();
    }
  },
  created() {
    this.index();

  },
  components: {
    LoadingView,
    MediaBrowser,
    AttachmentsUpload
  },
};
</script>
