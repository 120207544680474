<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Info</h2>
        <nav v-if="GlobalPolicy.edit()" class="flex items-end gap-2">
            <FormButton @click="edit()" v-if="!editing" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-pencil">
                Edit
            </FormButton>
            <template v-else>
                <FormButton @click="cancel()" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300" icon="fa-cancel">
                    Cancel
                </FormButton>
                <FormButton @click="save" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-save">
                    Save
                </FormButton>
            </template>
        </nav>

    </div>
    <p class="text-sm text-oakwood-gray bg-oakwood-gray-50 p-2 mt-4 rounded border border-oakwood-gray-300">
        <span class="far fa-circle-exclamation mr-2"></span>
        This data is inherited from Kova. Any changes made here will not update Kova, but will override those values.
    </p>
    <dl class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-if="!editing">
        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Name</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ floorplan.meta?.info?.name }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Marketing Headline</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ floorplan.meta?.info?.headline }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Property Description</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ floorplan.meta?.info?.description }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Price</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ priceRange }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Square Feet</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ sqftRange }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Bedrooms</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ bedRange }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Full Bathrooms</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ fullBathRange }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Half Bathrooms</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ halfBathRange }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Garages</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ garageRange }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Plan Website</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4"><a class="text-oakwood-blue-500 hover:underline" :href="floorplan.meta.info.website" target="_blank" title="Visit this home's website">{{ floorplan.meta.info.website }}</a></dd>
    </dl>

    <fieldset class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-else>
        <legend class="sr-only">Custom Properties</legend>

        <label for="info.name" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Name</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <input type="text" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" id="info.name" v-model="item.meta.info.name"/>
        </div>

        <label for="info.headline" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Marketing Headline</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <input placeholder="Example: 'Luxury amenities and a central media room'" type="text" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" id="info.headline" v-model="item.meta.info.headline"/>
        </div>

        <label for="info.description" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Property Description</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <textarea class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400 min-h-48" id="info.description" v-model="item.meta.info.description"></textarea>
        </div>

        <div class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Price</div>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 md:inline-flex items-center gap-4">
            <NumberField :max="item.meta.info.price.max"  v-model="item.meta.info.price.min" prefix="$" label="Min" aria-label="Minimum Price" />
            <NumberField max="9999999" v-model="item.meta.info.price.max" prefix="$" label="Max" aria-label="Maximum Price" />
        </div>

        <div class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Square Feet</div>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 md:inline-flex items-center gap-4">
            <NumberField width="w-12" :max="item.meta.info.sqft.max" v-model="item.meta.info.sqft.min" label="Min" aria-label="Minimum Square Feet" />
            <NumberField width="w-12" max="99999" v-model="item.meta.info.sqft.max" label="Max" aria-label="Maximum Square Feet" />
        </div>

        <div class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Bedrooms</div>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 md:inline-flex items-center gap-4">
            <NumberField width="w-10" :max="item.meta.info.bedrooms.max" v-model="item.meta.info.bedrooms.min" label="Min" aria-label="Minimum Bedrooms" />
            <NumberField width="w-10" max="10" v-model="item.meta.info.bedrooms.max" label="Max" aria-label="Maximum Bedrooms" />
        </div>

        <div class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Full Bathrooms</div>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 md:inline-flex items-center gap-4">
            <NumberField width="w-10" :max="item.meta.info.bathrooms.max.full" v-model="item.meta.info.bathrooms.min.full" label="Min" aria-label="Minimum Full Bathrooms" />
            <NumberField width="w-10" max="10" v-model="item.meta.info.bathrooms.max.full" label="Max" aria-label="Maximum Full Bathrooms" />
        </div>

        <div class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Half Bathrooms</div>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 md:inline-flex items-center gap-4">
            <NumberField width="w-10" :max="item.meta.info.bathrooms.max.half" v-model="item.meta.info.bathrooms.min.half" label="Min" aria-label="Minimum Half Bathrooms" />
            <NumberField width="w-10" max="10" v-model="item.meta.info.bathrooms.max.half" label="Max" aria-label="Maximum Half Bathrooms" />
        </div>

        <div class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Garages</div>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 md:inline-flex items-center gap-4">
            <NumberField width="w-12" step=".5" :max="item.meta.info.garages.max" v-model="item.meta.info.garages.min" label="Min" aria-label="Minimum Garages" />
            <NumberField width="w-12" step=".5" max="10" v-model="item.meta.info.garages.max" label="Max" aria-label="Maximum Garages" />
        </div>

        <label for="info.website" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Plan Website</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <URLField id="info.website" v-model="item.meta.info.website" class="w-full" />
        </div>

    </fieldset>
       
</template>

<script>
import { formatToCurrency } from '@/helpers/numberHelpers';
import {NumberField, FormButton, SelectField, URLField} from '@/components/form';
import {floorplan} from '@/helpers/globals';
import {helpers} from '@/models/Floorplan';
import { GlobalPolicy } from '@/policies/global';

export default {
    setup() {
        return {
            GlobalPolicy
        }
    },
    data() {
        return {
            item: {},
            editing: false,
            statuses: floorplan.ModelStatus
        }
    },
    methods: {
        edit() {
            this.editing = true;
            this.item = {...this.floorplan};
        },
        cancel() {
            this.editing = false;
        },
        save() {
            this.$emit('update', this.item);
            this.cancel();
        },
        formatToCurrency
    },
    computed: {
        ...helpers
    },
    emits: [
        'update'
    ],
    props: {
        floorplan: {
            type: Object,
            required: true
        }
    },
    components: {
        FormButton,
        NumberField,
        SelectField,
        URLField
    }
}
</script>@/components/form