import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '@/views/dashboard';
import Floorplans from '@/views/floorplans';
import MarketHomes from '@/views/market_homes';
import Communities from '@/views/communities';
import Collections from '@/views/collections';
import Agents from '@/views/agents';
import Media from '@/views/media';
import Users from '@/views/users';
import Surveys from '@/views/surveys';
import Error404 from '@/views/404.vue';
import { useAuthStore } from '@/stores/auth';

import axios from 'axios';
import { useMessageStore } from './stores/message';
axios.defaults.baseURL = `${import.meta.env.VITE_APP_ENDPOINT}api/`;
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const routes = [
    {
      path: '/',
      component: Dashboard.ListView,
      name: 'dashboard',
      meta: {
        icon: 'fa-gauge',
        menu: true,
        title: 'Dashboard',
        order: 1,
        acl: 0
      }
    },
    {
      path: '/available-homes/',
      component: MarketHomes.ListView,
      name: 'available-homes-list',
      meta: {
        icon: 'fa-sign-hanging',
        menu: true,
        title: 'Available Homes',
        order: 4,
        acl: 0
      }
    },
    {
      path: '/available-homes/:id',
      component: MarketHomes.ShowView, 
      name: 'available-homes-show',
      meta: {
        title: 'Available Home',
        parent: 'available-homes-list',
        acl: 0
      },
      children: [
        {
          path: ':tab',
          name: 'available-homes-tab', 
          component: MarketHomes.ShowView
        }
      ]
    },
    {
      path: '/media/',
      component: Media.ListView,
      name: 'media-list',
      meta: {
        icon: 'fa-image',
        title: 'Media Manager',
        menu: true,
        order: 5,
        acl: 0
      }
    },
    {
      path: '/media/:id',
      component: Media.ShowView,
      name: 'media-show',
      meta: {
        parent: 'media-list',
        title: 'Media Item Details',
        acl: 0
      },
      children: [
        {
          path: ':tab',
          name: 'media-tab', 
          component: Media.ShowView
        }
      ]
    },
    {
      path: '/media/edit/:id',
      component: Media.ShowView,
      name: 'media-edit',
      meta: {
        parent: 'media-list',
        title: 'Edit Media Item',
        acl: 1
      }
    },
    {
      path: '/floorplans',
      component: Floorplans.ListView,
      name: 'floorplan-list',
      meta: {
        icon: 'fa-house',
        title: 'Floorplans',
        menu: true,
        order: 3,
        acl: 0
      }
    },
    {
      path: '/floorplans/:id',
      component: Floorplans.ShowView,
      name: 'floorplan-show',
      meta: {
        parent: 'floorplan-list',
        title: 'Floorplan',
        acl: 0
      },
      children: [
        {
          path: ':tab',
          name: 'floorplan-tab', 
          component: Floorplans.ShowView
        }
      ]
    },
    {
      path: '/communities',
      component: Communities.ListView,
      name: 'community-list',
      meta: {
        icon: 'fa-signs-post',
        title: 'Communities',
        menu: true,
        order: 2,
        acl: 0
      }
    },
    {
      path: '/communities/:id',
      component: Communities.ShowView,
      name: 'community-show',
      meta: {
        parent: 'community-list',
        title: 'Community',
        acl: 0
      },
      children: [
        {
          path: ':tab',
          name: 'community-tab', 
          component: Communities.ShowView
        }
      ]
    },
    {
      path: '/collections',
      component: Collections.ListView,
      name: 'collection-list',
      meta: {
        icon: 'fa-grid-round-2',
        title: 'Collections',
        menu: true,
        order: 2.1,
        acl: 0
      }
    },
    {
      path: '/collections/:id',
      component: Collections.ShowView,
      name: 'collection-show',
      meta: {
        parent: 'collection-list',
        title: 'Collection',
        acl: 0
      },
      children: [
        {
          path: ':tab',
          name: 'collection-tab', 
          component: Collections.ShowView
        }
      ]
    },
    {
      path: '/users',
      component: Users.ListView,
      name: 'user-list',
      meta: {
        icon: 'fa-users',
        title: 'Users',
        menu: true,
        order: 6,
        acl: 2
      }
    },
    {
      path: '/users/add',
      component: Users.EditView,
      name: 'user-add',
      meta: {
        title: 'Add New User',
        parent: 'user-list',
        acl: 2
      }
    },
    {
      path: '/users/:id/edit',
      component: Users.EditView,
      name: 'user-edit',
      meta: {
        title: 'Edit User',
        parent: 'user-list',
        acl: 2
      }
    },
    {
      path: '/profile/edit',
      component: Users.EditView,
      name: 'profile-edit',
      meta: {
        title: 'Edit My Profile',
        acl: 0
      }
    },
    {
      path: '/agents',
      component: Agents.ListView,
      name: 'agent-list',
      meta: {
        icon: 'fa-handshake-simple',
        title: 'Sales Team',
        menu: true,
        order: 5.1,
        acl: 0
      }
    },
    {
      path: '/agents/:id',
      component: Agents.EditView,
      name: 'agent-edit',
      meta: {
        title: 'Edit Sales Team Member',
        parent: 'agent-list',
        acl: 1
      }
    },
    {
      path: '/agents/add',
      component: Agents.EditView,
      name: 'agent-add',
      meta: {
        title: 'Add New Sales Team Member',
        parent: 'agent-list',
        acl: 1
      }
    },
    {
      path: '/surveys',
      component: Surveys.ListView,
      name: 'survey-list',
      meta: {
        icon: 'fa-crosshairs',
        title: 'Surveys',
        menu: true,
        order: 5.2,
        acl: 2
      }
    },
    {
      path: '/surveys/:id',
      component: Surveys.ShowView,
      name: 'survey-show',
      meta: {
        title: 'Survey',
        parent: 'survey-list',
        acl: 2
      },
      children: [
        {
          path: ':tab',
          name: 'survey-tab', 
          component: Surveys.ShowView
        }
      ]
    },
    {
      path: '/surveys/add',
      component: Surveys.AddView,
      name: 'survey-add',
      meta: {
        title: 'Add New Survey',
        parent: 'survey-list',
        acl: 1
      }
    },
    {
      path: '/parcels/:id',
      name: 'parcel-show'
    },
    {
      path: '/:pathMatch(.*)*',
      name: 404,
      component: Error404,
      meta: {
        title: 'Page Not Found'
      }
    }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ top: 0, left: 0 });
    })
  }
});

router.beforeEach(async (to) => {
  const store = useAuthStore();
  let allow = true;
  await store.check()
    .then(() => {
      if(store.user.level < to.meta.acl && to.name != 'dashboard') {
        allow = { name : 'dashboard' };
        useMessageStore().setAlert({
          text: "You are not authorized to view that page",
          level: "error"
        })
      }
  });

  if(allow !== true) return allow;
})

export default router;
