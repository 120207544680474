<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Floorplans</h2>
    </div>

    <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4 mt-8">
        <div class="mb-4 flex-initial">
            <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Search by Keyword</label>
            <input v-model="query.search" placeholder="Search floorplans by keyword" id="search" name="search"
            type="text" autocomplete="off"
            class="p-4 mt-1 sm:w-72 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm border-oakwood-gray-100 border rounded-md h-8">
        </div>
        <div v-if="collections.length > 1" class="mb-4 flex-initial">
            <SelectField v-model="query.collection" label="Collections">
                <option :value="null">All Collections</option>
                <option v-for="collection,i in collections" :key="`collection-${i}`" :value="collection.id">{{ collection.meta.info.name }}</option>
            </SelectField>
        </div>
        <div class="mb-4 flex-initial">
            <SelectMultipleField v-model="query.status" label="Published" :options="statuses" empty="All Statuses"/>
        </div>
        <div v-if="hasFilters" class="mb-4 flex-initial sm:mt-5">
            <FormButton @click="clear" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
        </div>
    </div>

    <PaginationTable :data="filtered">
        <template #headers>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Floorplan
            </th>
            <th v-if="collections.length"
                scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Collection
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Sales Status
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider lg:w-1/5">
                Status
            </th>
        </template>
        <template #columns="{row}">
            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name:'floorplan-show', params: { id: row.id } }" class="block px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600">
                    {{ row.meta?.info?.headline || row.kova?.MarketingName }}
                </router-link>
            </td>

            <td v-if="collections.length" class="px-6 whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                {{ row.collection?.meta?.info?.name || row.collection?.kova?.MarketingName }}
            </td>

            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name:'floorplan-show', params: { id: row.id } }" class="block px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600">
                    <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full" :class="['Pre-Sale (VIP)', 'Off Market', 'Sold Out', 'Internal', '', 'N/S'].includes(row.kova.CustomFields.TextCommModelStatus1) ? 'bg-oakwood-red-100 text-oakwood-red-800' : 'bg-oakwood-green-100 text-oakwood-green-800'">
                        {{ row.kova.CustomFields.TextCommModelStatus1 }}
                    </span>
                </router-link>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900 flex items-center gap-2">
                <template v-if="GlobalPolicy.edit()">
                    <ToggleButton :id="`item-${row.id}-status`" @changed="save(row)" :checked="row.status == 'active'" 
                    :label="row.status == 'active' ? 'Published' : 'Unpublished'"/>
                    <label :for="`item-${row.id}-status`">{{ statuses.find(status => status.value == row.status).label }}</label>
                </template>
                <template v-else>
                    {{ statuses.find(status => status.value == row.status).label }}
                </template>
            </td>
        </template>
    </PaginationTable>
       
</template>

<script>
import axios from 'axios';
import {FormButton, SelectField, SelectMultipleField, ToggleButton} from '@/components/form';
import PaginationTable from '@/components/PaginationTable.vue';
import { statuses } from '@/helpers/globals';
import { clean } from '@/helpers/regex';
import { GlobalPolicy } from '@/policies/global';

const query = {
    search: null,
    collection: null,
    status: ['active', 'new']
}

export default {
    setup() {
        return {
            GlobalPolicy
        }
    },
    data() {
        return {
            statuses,
            query: {...query}
        }
    },
    emits: [
        'update'
    ],
    props: {
        floorplans: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        save(item) {
            item.status = item.status != 'active' ? 'active' : 'inactive';
            axios.patch(`floorplans/${item.id}`, item)
                .then(res => {
                    if(res.data?.id) {
                        this.$emit('update',res.data);
                    }
                })
                .catch(e => {
                    throw e;
                });
        },
        clear() {
            this.query = {...query};
        }
    },
    computed: {
        filtered() {
            let results = this.floorplans;

            if (this.query.status.length) {
                results = results.filter(item => this.query.status.includes(item.status));
            }

            if (this.query.collection) {
                results = results.filter(item => item.collection.id == this.query.collection);
            }

            if(this.query.search) {
                const regex = new RegExp(clean(this.query.search), 'i');
                results = results.filter(result => regex.exec(result.meta?.info?.name) || regex.exec(result.meta?.info?.description) || regex.exec(result.kova?.CustomFields?.TextCommModelStatus1))
            }

            return results;
        },
        collections() {
            if(!this.floorplans[0]?.collection) return [];
            return [...new Set(this.floorplans.map(floorplan => JSON.stringify(floorplan.collection)))].map(floorplan => JSON.parse(floorplan))
        },
        hasFilters() {
            return JSON.stringify(query) !== JSON.stringify(this.query);
        }
    },
    components: {
        PaginationTable,
        ToggleButton,
        SelectMultipleField,
        SelectField,
        FormButton
    }
}
</script>@/components/form