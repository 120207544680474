<script>
import axios from 'axios';
import { FormButton, SelectField } from '../form';
import PaginationTable from '../PaginationTable.vue';
import TextInput from '../form/TextInput.vue';
import ModalDialog from '../ModalDialog.vue';
import { GlobalPolicy } from '@/policies/global';

const query = {
    search: '',
    collection: null
}

export default {
    setup() {
        return {
            GlobalPolicy,
            panes: [
                {
                    name: 'floorplan',
                    endpoint: 'floorplans',
                    label: 'Floorplans',
                    category: 'home'
                },
                {
                    name: 'sales_order',
                    endpoint: 'sales_orders',
                    label: 'Available Homes',
                    category: 'home'
                },
                {
                    name: 'collection',
                    endpoint: 'collections',
                    label: 'Collections',
                    category: 'community'
                },
                {
                    name: 'division',
                    endpoint: 'divisions/communities',
                    label: 'Communities',
                    category: 'community'
                }
            ]
        }
    },
    data() {
        return {
            attached: {
                floorplan: [],
                sales_order: [],
                collection: [],
                division: []
            },
            collections: [],
            items: [],
            query: {...query},
            context: null,
            selected: []
        }
    },
    methods: {
        index() {
            axios.get(`media/${this.id}/attachments`)
            .then(resp => {
                this.attached = resp.data;
            })
            .catch(err => {
                console.error(err);
            });
        },
        openAttach(context) {
            this.context = context;
            this.indexItems();
        },
        indexCollections() {
            axios.get('collections')
            .then(resp => {
                this.collections = resp.data.sort((a,b) => a.meta?.info?.name > b.meta?.info?.name ? 1 : -1);
            })
            .catch(err => {
                console.error(err);
            });
        },
        indexItems() {
            if(!this.context) return;
            let endpoint = this.context.endpoint;
            switch(endpoint) {
                case 'collections' :
                    this.items = this.collections;
                    break;
                default:
                    axios.get(endpoint)
                    .then(resp => {
                        this.items = endpoint === 'sales_orders' ? 
                        resp.data.sort((a,b) => a.kova.Lot.StreetAddress > b.kova.Lot.StreetAddress ? 1 : -1) :
                        resp.data.sort((a,b) => a.meta?.info?.name > b.meta?.info?.name ? 1 : -1);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }    
        },
        cancel() {
            this.context = null;
            this.items = [];
            this.selected = [];
            this.query = {...query};
        },
        sync() {
            axios.post(`media/${this.id}/attachments`, {items: this.selected, type: this.context.name})
            .then(resp => {
                this.attached[this.context.name] = resp.data;
                this.cancel();
            })
            .catch(err => {
                console.error(err);
            })
        },
        unsync(index, context) {
            let item = this.attached[context][index];
            axios.delete(`attachments/${item.id}`)
            .then(resp => {
                this.attached[context].splice(index, 1);
            })
            .catch(err => {
                console.error(err);
            })
        },
        getCollection(rid) {
            return this.collections.find(collection => collection.community_rid === rid) || '-';
        }
    },
    computed: {
        filtered() {
            let ids = this.attached[this.context.name].map(attach => attach.id);
            let items = this.items.filter(item => !ids.includes(item.id) && item.status !== 'inactive');

            if(this.query.search) {
                items = items.filter(item => JSON.stringify(item).toLowerCase().includes(this.query.search.toLowerCase()));
            }

            if(this.query.collection) {
                items = items.filter(item => item.kova.CommunityRID == this.query.collection);
            }

            return items;
        }
    },
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    created() {
        this.index();
        this.indexCollections();
    },
    components: {
        FormButton,
        SelectField,
        TextInput,
        ModalDialog,
        PaginationTable
    }
}
</script>

<template>
    <h2 class="text-xl text-oakwood-blue-600 font-medium">Attached Locations</h2>
    <template v-for="pane, i in panes" :key="`pane-${i}`">
        <div class="flex justify-between items-center mt-8">
            <h3 class="text-lg text-oakwood-red-600 font-medium">{{ pane.label }}</h3>
            <div>
                <FormButton v-if="GlobalPolicy.edit()" @click="openAttach(pane)" icon="fa-plus-circle" class="bg-oakwood-blue-600 hover:bg-oakwood-blue-700 text-white">
                    Bulk Attach
                </FormButton>
            </div>
        </div>
        <PaginationTable :data="attached[pane.name]" :limit="5">
            <template #headers>
                <th scope="col"
                    class="w-1/3 px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Name
                </th>
                <th v-if="['floorplan','sales_order'].includes(pane.name)" scope="col"
                    class="w-1/3 px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Collection
                </th>
                <th scope="col" class="w-1/3 px-6 py-3 bg-oakwood-gray-50 text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider text-right">
                    <span class="sr-only">Actions</span>
                </th>
            </template>
            <template v-if="pane.name === 'floorplan'" #columns="{row, i:k}">
                <td class="whitespace-no-wrap text-sm">
                    <router-link :to="{name: 'floorplan-tab', params: {id: row.model.id, tab: 'photos'}}" class="text-oakwood-blue-600 px-6 py-4 block">
                        {{ row.model?.meta?.info?.name }}
                    </router-link>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm">
                    {{ row?.model?.collection?.meta?.info?.name }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
                    <FormButton v-if="GlobalPolicy.edit()" @click="unsync(k, pane.name)" title="Detach the image from this item" icon="fa-unlink" class="bg-oakwood-red-600 hover:bg-oakwood-red-700 text-white">
                        <span class="sr-only">Detach</span>
                    </FormButton>
                </td>
            </template>

            <template v-else-if="pane.name === 'sales_order'" #columns="{row, i:k}">
                <td class="whitespace-no-wrap text-sm">
                    <router-link :to="{name: 'available-homes-tab', params: {id: row.model.id, tab: 'photos'}}" class="text-oakwood-blue-600 px-6 py-4 block">
                        {{ row.model?.kova?.Lot?.StreetAddress }}, {{ row.model?.kova?.Lot?.City }}, {{ row.model?.kova?.Lot?.State }} {{ row.model?.kova?.Lot?.ZipCode }}
                    </router-link>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm">
                    {{ row?.model?.collection?.meta?.info?.name }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
                    <FormButton v-if="GlobalPolicy.edit()" @click="unsync(k, pane.name)" title="Detach the image from this item" icon="fa-unlink" class="bg-oakwood-red-600 hover:bg-oakwood-red-700 text-white">
                        <span class="sr-only">Detach</span>
                    </FormButton>
                </td>
            </template>

            <template v-else-if="pane.name === 'collection'" #columns="{row, i:k}">
                <td class="whitespace-no-wrap text-sm">
                    <router-link :to="{name: 'collection-tab', params: {id: row.model.id, tab: 'photos'}}" class="text-oakwood-blue-600 px-6 py-4 block">
                        {{ row.model.meta?.info?.name }}
                    </router-link>   
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
                    <FormButton v-if="GlobalPolicy.edit()" @click="unsync(k, pane.name)" title="Detach the image from this item" icon="fa-unlink" class="bg-oakwood-red-600 hover:bg-oakwood-red-700 text-white">
                        <span class="sr-only">Detach</span>
                    </FormButton>
                </td>
            </template>

            <template v-else-if="pane.name === 'division'" #columns="{row, i:k}">
                <td class="whitespace-no-wrap text-sm">
                    <router-link :to="{name: 'community-tab', params: {id: row.model.id, tab: 'photos'}}" class="text-oakwood-blue-600 px-6 py-4 block">
                        {{ row.model.meta?.info?.name }}
                    </router-link> 
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
                    <FormButton v-if="GlobalPolicy.edit()" @click="unsync(k, pane.name)" title="Detach the image from this item" icon="fa-unlink" class="bg-oakwood-red-600 hover:bg-oakwood-red-700 text-white">
                        <span class="sr-only">Detach</span>
                    </FormButton>
                </td>
            </template>
        </PaginationTable>
    </template>

    <ModalDialog v-if="context" :open="context !== null" @close="cancel">
        <template #header>
            <h2>Bulk Attach {{ context.label }}</h2>
        </template>
        <template #body>
            <div class="flex items-center gap-4">
                <div v-if="['floorplan','sales_order'].includes(context.name)">
                    <SelectField v-model="query.collection">
                        <option :value="null">All Collections</option>
                        <option v-for="collection, i in collections" :key="`collection-${i}`" :value="collection.community_rid">{{ collection.meta?.info?.name }}</option>
                    </SelectField>
                </div>
                <div>
                    <TextInput v-model="query.search" placeholder="Search by keyword" />
                </div>
            </div>
            <PaginationTable :data="filtered" :limit="5">
                <template #headers>
                    <th scope="col"
                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                        <!-- <label for="select_all">Select All</label>
                        <input id="select_all" type="checkbox" v-model="all" :value="all || true"> -->
                    </th>
                    <th scope="col"
                        class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                        Name
                    </th>
                    <th v-if="['floorplan','sales_order'].includes(context.name)" scope="col"
                        class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                        Collection
                    </th>
                </template>
                <template #columns="{row}">
                    <th scope="row" class="px-6 py-3">
                        <input type="checkbox" v-model="selected" :value="row.id">
                    </th>
                    <td class="px-6 py-3">
                        {{ context.name == 'sales_order' ? `${row.kova.Lot.StreetAddress}` : row.meta?.info?.name || row.kova.Name }}
                    </td>
                    <td class="px-6 py-3" v-if="['floorplan','sales_order'].includes(context.name)" scope="col">
                        {{ getCollection(row.kova?.CommunityRID)?.meta?.info?.name }}
                    </td>
                </template>
            </PaginationTable>
        </template>
        <template #buttons>
            <FormButton @click="cancel" icon="fa-cancel" class="bg-oakwood-gray-200">Cancel</FormButton>
            <FormButton @click="sync" :disabled="!selected.length" icon="fa-link" class="bg-oakwood-blue-500 hover:bg-oakwood-blue-600 text-white">Attach to Selected</FormButton>
        </template>
    </ModalDialog>
</template>